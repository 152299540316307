import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import HomeLayout from '../components/layouts/HomeLayout'
import Seo from '../components/seo'
import ArticleBanner from '../components/common/ArticleBanner/ArticleBanner'
import Badge from '../components/common/Badge/Badge'
import CardSlider from '../components/common/CardSlider/CardSlider'
import { Link, useIntl } from 'gatsby-plugin-intl'
import Card from '../components/common/Card/Card'
import Article from '../components/widgets/Article/Article'
import useLink from '../hooks/useLink'

const PagesTemplate = props => {
  const location = typeof window !== 'undefined' ? window.location : { pathname: '', href: '' }
  const pageData = get(props, 'data.contentfulPage')
  const otherPages = get(props, 'data.otherPages.edges', []).map(page => page.node)
  const intl = useIntl()
  const { buildURL, buildRootURL } = useLink()

  return (
    <HomeLayout location={location}>
      {pageData && (
        <>
          <Seo title={pageData.title} description={pageData.description} />
          <ArticleBanner title={pageData.title} image={pageData.image} location={location} />
          <section className="container">
            <div className="small-content">
              {pageData.description && (
                <div className="intro">{pageData.description.description}</div>
              )}
              <p>
                {intl.formatDate(pageData.updatedAt, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </p>
              {pageData.tags && (
                <ul className="list-unstyled d-flex flex-wrap">
                  {pageData.tags.map(tag => (
                    <li key={tag.id} className="mx-2 mb-2">
                      <Badge {...tag} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </section>
          <div className="container py-2 pb-md-5">
            <div className="small-content">
              <Article
                body={
                  typeof pageData.article?.raw === 'string'
                    ? JSON.parse(pageData.article.raw)
                    : pageData.article?.raw
                }
                references={pageData.article?.references}
              />
            </div>
          </div>
          {otherPages.length > 0 && (
            <section className="page-section bg-light">
              <div className="container">
                <h2 className="section-title text-primary">
                  {intl.formatMessage({ id: `others.pageType.${pageData.pageType}` })}
                </h2>
                <CardSlider
                  cards={otherPages.map(page => (
                    <Card to={buildURL(page)} {...page} description={''} key={page.id} />
                  ))}
                />
              </div>
              <div className="text-center container pt-5">
                <Link to={buildRootURL(pageData)} className={'btn btn-outline-dark block-on-sm'}>
                  {intl.formatMessage({ id: `see-more.pageType.${pageData.pageType}` })}
                </Link>
              </div>
            </section>
          )}
        </>
      )}
    </HomeLayout>
  )
}

export default PagesTemplate

export const pageQuery = graphql`
  fragment PageBasicInformation on ContentfulPage {
    __typename
    id: contentful_id
    slug
    title
    pageType
    category
    node_locale
  }
  query PagesBySlug($slug: String, $locale: String, $pageType: String) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }, pageType: { eq: $pageType }) {
      ...PageBasicInformation
      image {
        id
        file {
          url
        }
        title
      }
      article {
        raw
        references {
          ...ContactCardFragment
          ... on ContentfulWidgetImage {
            __typename
            id: contentful_id
            images {
              file {
                url
              }
              title
            }
            position
            title
          }
          ...AccessPlanFragment
          ...NewsletterFragment
        }
      }
      description {
        description
      }
      updatedAt
    }
    otherPages: allContentfulPage(
      filter: { node_locale: { eq: $locale }, slug: { ne: $slug }, pageType: { eq: $pageType } }
    ) {
      edges {
        node {
          ...PageBasicInformation
          image {
            title
            file {
              url
            }
          }
          updatedAt
        }
      }
    }
  }
`
